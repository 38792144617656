<template>
  <div class="container">
    <!-- 标题 -->
    <h3 @click="$router.go(-1)" class="header-title el-icon-arrow-left"> 当前角色：{{currentRole.name}}</h3>

    <div class="content">
      <!-- 操作区 -->
      <div class="header-handle">
        <el-input style="width: 200px" placeholder="请输入员工姓名" suffix-icon="el-icon-search"
          v-model="searchName">
        </el-input>
      </div>

      <div class="file-list">
        <!-- 角色表格 -->
        <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px"
          :data="memberList.filter(e => !searchName || e.name.toLowerCase().includes(searchName.toLowerCase()))"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
          <!-- 姓名 -->
          <el-table-column prop="name" label="姓名"></el-table-column>
          <!-- 职位 -->
          <el-table-column prop="position" label="职位"></el-table-column>
          <!-- 部门 -->
          <el-table-column prop="teamName" label="部门"></el-table-column>
          <!-- 手机 -->
          <el-table-column prop="tel" label="手机"></el-table-column>
          <!-- 移除 -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button style="color: #ff0000" @click="delMember(scope.row)" type="text">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { 
    
  },
  data(){
    return {
      currentRole: { // 当前角色信息
        id: this.$route.query.id || '',
        name: this.$route.query.name || ''
      },
      searchName: '', // 搜索信息
      memberList: [], // 员工列表
    }
  },
  mounted(){
    this.getMembersByRole();
  },
  methods: {
    // 根据角色ID查询成员列表
    async getMembersByRole(){
      let res = await this.$ZGManager.getMembersByRole(this.$route.query.id);
      if(res.status == 200 && res.data){
        this.memberList = res.data;
      }
    },

    // 移除当前角色下的成员
    async delMember(item){
      let dialog = await this.$confirm(`您确定要移除“${item.name}”吗？将不可恢复。`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      });
      if(dialog != 'confirm') return;
      let index = item.roles.findIndex(e => e.id == this.$route.query.id);
      item.roles.splice(index, 1);
      let res = await this.$ZGManager.updateMemberInfo(item.id, {roles: item.roles});
      if(res.status == 200){
        this.getMembersByRole();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-title{
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.content{
  background-color: #fff;
  padding: 15px;
}
.header-handle{

}
</style>